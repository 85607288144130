import { lazy, Suspense } from 'react';
import { getSpacing, Spinner, styled, View } from '@talkspace/react-toolkit';

import CalloutNumber from './CalloutNumber';
import { getUserData } from '../../../../utils/token';
import useQueryClinicalHours from '../queries/useQueryClinicalHours';

const WeekGraph = lazy(() => import('../WeekGraph'));

const MainView = styled(View)(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      flexDirection: isMobile ? 'column' : 'row',
      alignItems: 'center',
      gap: getSpacing(isMobile ? 'space200' : 'space300'),
    };
  }
);

const GraphView = () => {
  const therapistID = getUserData().id;

  const { data, isLoading } = useQueryClinicalHours(therapistID);

  if (isLoading || !data) {
    return <Spinner />;
  }

  const {
    weeklyStats: [, thisWeek],
    weeklyHoursGoal,
  } = data;

  return (
    <MainView>
      <CalloutNumber
        title="Completed this week"
        metric={`${thisWeek.totalHours} hrs`}
        type={Number(thisWeek.totalHours) >= weeklyHoursGoal ? 'success' : 'default'}
      />

      <Suspense fallback={<Spinner />}>
        <WeekGraph />
      </Suspense>
    </MainView>
  );
};

export default GraphView;
