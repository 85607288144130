import { StarShooting, TrendArrow } from '@talkspace/react-toolkit/src/designSystems/icons';
import { Button, getSpacing, styled, TextDS, useWindowWidth, View } from '@talkspace/react-toolkit';
import { useHistory } from '@/core/routerLib';

import { navigateToBillableHoursZendesk } from '../utils/zendesk';
import { BonusInfo } from '../entities/clinicalHours';
import { NPP_UPPER_WEEKLY_HOURS_LIMIT } from '../utils/constants';

const BoldText = ({ children }) => (
  <TextDS inline style={{ fontWeight: 600 }}>
    {children}
  </TextDS>
);

const CTAButton = styled(Button)({
  padding: `${getSpacing('space075', true)} ${getSpacing('space100', true)}`,
});

const CTAButtons = () => {
  const { isMobile } = useWindowWidth();
  const history = useHistory();

  const handleIncreaseHoursPress = async () => {
    await navigateToBillableHoursZendesk();
  };

  const handleAddAvailabilityPress = () => {
    history.push('/calendar');
  };

  const handleGrowCaseloadPress = () => {
    history.push('/grow-caseload');
  };

  return (
    <View style={{ flexDirection: isMobile ? 'column' : 'row' }}>
      <CTAButton
        variant="tertiary"
        text="How can I increase my hours?"
        onPress={handleIncreaseHoursPress}
      />
      <CTAButton
        variant="tertiary"
        text="Add live session availability"
        onPress={handleAddAvailabilityPress}
      />
      <CTAButton variant="tertiary" text="Grow caseload" onPress={handleGrowCaseloadPress} />
    </View>
  );
};

const BonusInfoText = styled(TextDS)(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      maxWidth: isMobile ? 308 : 540,
      marginBottom: getSpacing('space050'),
    };
  }
);

const RowView = styled(View)({
  flexDirection: 'row',
  gap: getSpacing('space200'),
});

interface Props {
  bonusInfo: BonusInfo;
  weeklyHoursGoal: number;
}

const BonusInfoView = ({ bonusInfo, weeklyHoursGoal }: Props) => {
  if (bonusInfo.currentBonus) {
    const { currentBonus, potentialBonus } = bonusInfo;

    const { amount, threshold, requiredAverage } = potentialBonus || {};

    return (
      <>
        <RowView>
          <TrendArrow size="major" colorType="success" />

          <TextDS>
            You’re currently on track for a <BoldText>{currentBonus} bonus</BoldText>
          </TextDS>
        </RowView>

        {potentialBonus && (
          <RowView>
            <StarShooting size="major" colorType="info" />

            <View>
              <BonusInfoText>
                In order to earn a <BoldText>{amount}</BoldText> bonus with an average of{' '}
                {threshold} hours per week, complete {requiredAverage} hours per week during the
                remaining weeks.
              </BonusInfoText>

              <CTAButtons />
            </View>
          </RowView>
        )}
      </>
    );
  }

  if (
    bonusInfo.potentialBonus &&
    Number(bonusInfo.potentialBonus.requiredAverage) < NPP_UPPER_WEEKLY_HOURS_LIMIT
  ) {
    const {
      potentialBonus: { amount, threshold, requiredAverage },
    } = bonusInfo;

    return (
      <>
        <RowView>
          <StarShooting size="major" colorType="info" />

          <View>
            <BonusInfoText>
              You’re close to being on track for a <BoldText>{amount}</BoldText> bonus. Complete{' '}
              {requiredAverage} hours per week during the remaining weeks to bring your average up
              to {threshold} hrs/wk.
            </BonusInfoText>

            <CTAButtons />
          </View>
        </RowView>
      </>
    );
  }

  return (
    <>
      <RowView>
        <TrendArrow size="major" colorType="warning" />

        <View>
          <BonusInfoText>
            Your average is below {weeklyHoursGoal} hours per week. Please reach out to your team
            lead for support or increase your live session availability and caseload.
          </BonusInfoText>

          <CTAButtons />
        </View>
      </RowView>
    </>
  );
};

export default BonusInfoView;
