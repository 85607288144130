import { Modal, View, COLORS } from '@talkspace/react-toolkit';

import ChatModalRoutes from 'chat/ModalRoutes';
import { Route } from './core/routerLib';
import styled from './core/styled';
import { useModalFocusTrap } from './modules/utils/ModalsContextProvider';
import Offers from './modules/therapistChat/components/Offers';
import KeepMessagingModal from './modules/therapistChat/components/KeepMessagingModal';
import BiometricLock from './components/BiometricLock';
import PromptBiometricLock from './components/PromptBiometricLock';

const Container = styled(View)<{ fullScreen: boolean; transparent: boolean }>(
  ({
    fullScreen,
    transparent,
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      position: 'absolute',
      overflowY: 'auto',
      height: fullScreen || isMobile ? '100%' : undefined,
      width: fullScreen || isMobile ? '100%' : undefined,
      backgroundColor: !transparent ? COLORS.white : undefined,
    };
  }
);

export default function ModalRoutes({ fullScreen, transparent }) {
  const { focusTrapPaused } = useModalFocusTrap();
  return (
    <Route path="/">
      <Modal isVisible focusTrapPaused={focusTrapPaused}>
        <Container fullScreen={fullScreen} transparent={transparent}>
          <Route path="/room-offer" component={Offers} />
          <Route path="/keep-messaging" component={KeepMessagingModal} />
          <Route path="/register-biometric-lock" component={BiometricLock} />
          <Route path="/biometric-lock" component={PromptBiometricLock} />
          <ChatModalRoutes isTherapistChat />
        </Container>
      </Modal>
    </Route>
  );
}
