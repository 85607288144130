import {
  Standard,
  styled,
  TextInput,
  TouchableView,
  View,
  useEmotionTheme,
  ExtraTiny,
  MinusIcon,
} from '@talkspace/react-toolkit';
import { ChangeEvent, FunctionComponent } from 'react';
import IndentedView from '../../IndentedView';
import { EmotionStyle } from '@/core/styled';

const RemovePersonButton = styled(TouchableView)(({ theme: { colors } }) => {
  return {
    flexDirection: 'row',
    align: 'center',
    alignSelf: 'flex-end',
    position: 'absolute',
    zIndex: 1,
    top: 25,
  };
});

interface Props {
  style?: EmotionStyle;
  namePlaceholder: string;
  nameValue?: string | null;
  onNameChange: (newName: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  relationshipValue?: string | null;
  onRelationshipChange: (newName: ChangeEvent<HTMLInputElement>) => void;
  onRemovePress?: () => void;
  isNameError?: boolean;
  isRelationshipError?: boolean;
}

const ContactNameWithRelationship: FunctionComponent<Props> = ({
  style,
  namePlaceholder,
  nameValue,
  onNameChange,
  disabled = false,
  relationshipValue,
  onRelationshipChange,
  onRemovePress,
  isNameError,
  isRelationshipError,
}) => {
  const { colors } = useEmotionTheme();
  return (
    <View style={{ position: 'relative', ...style }}>
      {!disabled && (
        <RemovePersonButton onPress={onRemovePress}>
          <Standard variant="standardBoldAccessibilityGreen">
            <MinusIcon /> Remove
          </Standard>
        </RemovePersonButton>
      )}

      <TextInput
        isPlaceholderAboveInput={false}
        placeHolderStyle={{ color: colors.grey950 }}
        placeholder={nameValue ? null : namePlaceholder}
        style={{
          marginBottom: 10,
          ...(isNameError ? { borderColor: colors.permaFuchsia } : {}),
        }}
        value={nameValue}
        onChange={onNameChange}
        disabled={disabled}
      />
      {isNameError && (
        <ExtraTiny
          data-testid="attendeeError"
          style={{ marginTop: -10, marginBottom: 4 }}
          variant="extraTinyError"
        >
          Required
        </ExtraTiny>
      )}
      <IndentedView>
        <TextInput
          placeHolderStyle={{ color: colors.grey950 }}
          placeholder="Relationship to identified client"
          style={{
            ...(isRelationshipError ? { borderColor: colors.permaFuchsia } : {}),
          }}
          value={relationshipValue}
          onChange={onRelationshipChange}
          disabled={disabled}
        />
        {isRelationshipError && <ExtraTiny variant="extraTinyError">Required</ExtraTiny>}
      </IndentedView>
    </View>
  );
};

export default ContactNameWithRelationship;
