export interface ClaimableRoom {
  roomID: number;
  clientUserID: number;
  clientGender: string;
  clientAge: number;
  presentingProblem: string[];
  country: string;
  state?: string;
  accountType: AccountType;
  numberOfSessions?: string;
  roomType?: RoomType;
  treatmentIntake: boolean;
  preference: string;
}

export type RoomType =
  | 'peer_support_group_room'
  | 'private_room'
  | 'psychiatry_room'
  | 'couples_room';

export interface SuccessfulClaimClientInfo {
  name: Name;
  roomID: number;
}

export interface Name {
  firstName: string;
  lastName: string;
}

export type Modality = 'messaging' | 'video' | 'audio' | 'chat';

export type AccountType = keyof typeof accountTypeDict;

export const accountTypeDict = {
  eap: 'eap',
  bh: 'bh',
  dte: 'dte',
  b2c: 'b2c',
  smb: 'smb',
  'channel/broker/tpa': 'channel/broker/tpa',
  edu: 'edu',
};

export const CLIENT_ALREADY_CLAIMED_STATUSES = [409, 423];
