import {
  REQUEST_DOSESPOT_PATIENT,
  REQUEST_DOSESPOT_PATIENT_ERROR,
  RECEIVE_DOSESPOT_PATIENT,
  DOSESPOT_PATIENT_NOT_FOUND,
  CREATE_DOSESPOT_PATIENT,
  UPDATE_DOSESPOT_PATIENT,
  CREATE_DOSESPOT_PATIENT_SUCCESS,
  UPDATE_DOSESPOT_PATIENT_SUCCESS,
  UPDATE_DOSESPOT_PATIENT_ERROR,
  RECEIVE_DOSESPOT_SSO_VALUES,
} from '../constants/DoseSpotConstants';

const initialState = {
  doseSpotPatient: {
    firstName: null,
    lastName: null,
    dateOfBirth: null,
    gender: null,
    address: null,
    address2: null,
    city: null,
    state: null,
    zipcode: null,
    primaryPhone: null,
    doseSpotPatientID: null,
  },
  allowDoseSpotRetry: false,
  isRequestingDoseSpotPatient: false,
  requestDoseSpotPatientError: false,
  isUpdating: false,
  updateError: false,
  clientUserID: undefined,
  doseSpotPatientExists: undefined,
};

export default function doseSpot(state = initialState, action) {
  switch (action.type) {
    case REQUEST_DOSESPOT_PATIENT:
      return {
        ...state,
        isRequestingDoseSpotPatient: true,
        requestDoseSpotPatientError: false,
        clientUserID: action.clientUserID,
      };
    case REQUEST_DOSESPOT_PATIENT_ERROR:
      return {
        ...state,
        isRequestingDoseSpotPatient: false,
        requestDoseSpotPatientError: true,
        allowDoseSpotRetry: true,
      };
    case RECEIVE_DOSESPOT_PATIENT:
      return {
        ...state,
        isRequestingDoseSpotPatient: false,
        requestDoseSpotPatientError: false,
        doseSpotPatientExists: true,
        doseSpotPatient: {
          firstName: action.firstName,
          lastName: action.lastName,
          dateOfBirth: action.dateOfBirth,
          gender: action.gender,
          address: action.address,
          address2: action.address2,
          city: action.city,
          state: action.state,
          zipcode: action.zipcode,
          primaryPhone: action.primaryPhone,
          doseSpotPatientID: action.doseSpotPatientID,
        },
      };
    case DOSESPOT_PATIENT_NOT_FOUND:
      return {
        ...state,
        isRequestingDoseSpotPatient: false,
        requestDoseSpotPatientError: false,
        doseSpotPatientExists: false,
        doseSpotPatient: initialState.doseSpotPatient,
      };
    case CREATE_DOSESPOT_PATIENT:
    case UPDATE_DOSESPOT_PATIENT:
      return {
        ...state,
        isUpdating: true,
        updateError: false,
        clientUserID: action.clientUserID,
      };
    case CREATE_DOSESPOT_PATIENT_SUCCESS:
    case UPDATE_DOSESPOT_PATIENT_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        updateError: false,
        doseSpotPatientExists: true,
        doseSpotPatient: {
          ...state.doseSpotPatient,
          firstName: action.firstName,
          lastName: action.lastName,
          dateOfBirth: action.dateOfBirth,
          gender: action.gender,
          address: action.address,
          address2: action.address2,
          city: action.city,
          state: action.state,
          zipcode: action.zipcode,
          primaryPhone: action.primaryPhone,
        },
        clientUserID: action.clientUserID,
      };
    case UPDATE_DOSESPOT_PATIENT_ERROR:
      return {
        ...state,
        isUpdating: false,
        updateError: true,
      };
    case RECEIVE_DOSESPOT_SSO_VALUES:
      return {
        ...state,
        clinicID: action.clinicID,
        clinicianID: action.clinicianID,
        encryptedClinicianID: action.encryptedClinicianID,
        ssoCode: action.ssoCode,
      };
    default:
      return state;
  }
}
