import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { Spinner, View } from '@talkspace/react-toolkit';
import { PromiseMessageTypeNames } from 'ts-promise-message';
import ReactFrameService from '@/auth/reactFrame/ReactFrameService';
import { getDoseSpotPatient, getSSOValues } from '../../../../../actions/DoseSpotActions';
import AccordionContainer from '../../../../Reusable/AccordionContainer/AccordionContainer';
import PsychiatryIcon from '../../../../Icons/PsychiatryIcon';
import Button from '../../../../Reusable/Buttons/Button';
import { isIonic } from '@/auth/reactFrame/helpers';
import appConfig from '../../../../../utils/configs';

class Psychiatry extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoadingURL: false,
    };
    this.getCardText = this.getCardText.bind(this);
    this.getButton = this.getButton.bind(this);
    this.renderReferPsychiatry = this.renderReferPsychiatry.bind(this);
    this.generateDoseSpotPatientURL = this.generateDoseSpotPatientURL.bind(this);
  }

  getButton() {
    if (this.props.roomType === 'psychiatry_room' && this.props.therapistType !== 'psychiatrist') {
      return null;
    }
    if (this.props.roomType !== 'psychiatry_room')
      return (
        <Button
          title="Refer to psychiatry"
          clickHandler={() => {
            this.props.history.push(`/room/${this.props.roomID}/create-psychiatry-room`);
          }}
        />
      );
    if (
      this.props.roomType === 'psychiatry_room' &&
      this.props.therapistType === 'psychiatrist' &&
      this.props.isRequestingDoseSpotPatient
    )
      return (
        <View style={{ alignItems: 'flex-end' }}>
          <Spinner />
        </View>
      );
    if (
      this.props.roomType === 'psychiatry_room' &&
      this.props.therapistType === 'psychiatrist' &&
      this.props.allowDoseSpotRetry &&
      !this.props.doseSpotPatientID
    )
      return <Button title="Retry" clickHandler={() => this.props.getDoseSpotPatient()} />;
    if (
      this.props.roomType === 'psychiatry_room' &&
      this.props.therapistType === 'psychiatrist' &&
      !this.props.doseSpotPatientID
    )
      return (
        <Button
          title="Add client to DoseSpot"
          clickHandler={() => {
            this.props.history.push(`/room/${this.props.roomID}/dosespot-patient-form`);
          }}
        />
      );
    if (
      this.props.roomType === 'psychiatry_room' &&
      this.props.therapistType === 'psychiatrist' &&
      this.props.doseSpotPatientID
    )
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: 50,
          }}
        >
          <Button
            title="Edit Rx"
            isActive={!this.state.isLoadingURL}
            clickHandler={() => {
              if (isIonic()) {
                this.props.getSSOValues().then(() => {
                  window.location.href = this.generateDoseSpotPatientURL();
                });
              } else if (ReactFrameService.instance().isInFrame()) {
                this.setState({ isLoadingURL: true });
                this.props
                  .getSSOValues()
                  .then(() => {
                    ReactFrameService.instance().sendAsyncMessage(
                      PromiseMessageTypeNames.openBrowserWithURL,
                      { url: this.generateDoseSpotPatientURL() }
                    );
                    this.setState({ isLoadingURL: false });
                  })
                  .catch(() => {
                    this.setState({ isLoadingURL: false });
                  });
              } else {
                const openWindowReference = window.open();
                this.props.getSSOValues().then(() => {
                  openWindowReference.location = this.generateDoseSpotPatientURL();
                });
              }
            }}
          />
          <Button
            title="Edit client information"
            clickHandler={() => {
              this.props.history.push(`/room/${this.props.roomID}/dosespot-patient-form`);
            }}
          />
        </div>
      );
    return undefined;
  }

  getCardText() {
    if (this.props.roomType !== 'psychiatry_room')
      return 'Your client is not signed up for psychiatry';
    if (
      this.props.roomType === 'psychiatry_room' &&
      !this.props.doseSpotPatientID &&
      this.props.allowDoseSpotRetry
    )
      return 'There was an issue connecting to DoseSpot.';
    if (this.props.roomType === 'psychiatry_room' && !this.props.doseSpotPatientID)
      return 'Your client has not been added to DoseSpot yet';
    if (this.props.roomType === 'psychiatry_room' && this.props.doseSpotPatientID)
      return 'Access DoseSpot to prescribe medication';
    return '';
  }

  generateDoseSpotPatientURL() {
    const {
      clinicID,
      clinicianID,
      ssoCode,
      encryptedClinicianID,
      doseSpotPatient: {
        firstName,
        lastName,
        dateOfBirth,
        gender,
        address,
        city,
        state,
        zipcode,
        primaryPhone,
        doseSpotPatientID,
      },
    } = this.props;
    const urlEncodedSSOCode = encodeURIComponent(ssoCode);
    const urlEncodedEncryptedClinicianID = encodeURIComponent(encryptedClinicianID);
    const queryString = `?SingleSignOnClinicId=${clinicID}&SingleSignOnUserId=${clinicianID}&SingleSignOnPhraseLength=32&SingleSignOnCode=${urlEncodedSSOCode}&SingleSignOnUserIdVerify=${urlEncodedEncryptedClinicianID}&FirstName=${firstName}&LastName=${lastName}&DateOfBirth=${dateOfBirth}&Gender=${gender}&Address1=${address}&City=${city}&State=${state}&ZipCode=${zipcode}&PrimaryPhone=${primaryPhone}&PrimaryPhoneType=Cell&PatientId=${doseSpotPatientID}`;
    return `${appConfig.doseSpot.uiURL}${queryString}`;
  }

  renderReferPsychiatry() {
    return (
      <div
        className="refer-psychiatry-container"
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'spaceBetween' }}>
          <PsychiatryIcon style={{ flex: '1 1 50%' }} />
          <div style={{ color: '#ccc', fontSize: '15px', fontWeight: '500' }}>
            {this.getCardText()}
          </div>
        </div>
        {this.getButton()}
      </div>
    );
  }

  render() {
    return (
      <AccordionContainer
        title="Psychiatry"
        childComponents={[this.renderReferPsychiatry()]}
        dataQa="psychiatryAccordion"
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isRequestingDoseSpotPatient: state.doseSpot.isRequestingDoseSpotPatient,
    allowDoseSpotRetry: state.doseSpot.allowDoseSpotRetry,
    roomID: state.room.roomID,
    roomType: state.room.roomType,
    therapistType: state.availability.therapistType,
    doseSpotPatient: state.doseSpot.doseSpotPatient,
    doseSpotPatientID: state.doseSpot.doseSpotPatient.doseSpotPatientID,
    encryptedClinicianID: state.doseSpot.encryptedClinicianID,
    clinicID: state.doseSpot.clinicID,
    clinicianID: state.doseSpot.clinicianID,
    ssoCode: state.doseSpot.ssoCode,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getDoseSpotPatient, getSSOValues }, dispatch);
const PsychiatryContainer = connect(mapStateToProps, mapDispatchToProps)(Psychiatry);

export default withRouter(PsychiatryContainer);
