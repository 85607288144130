import { Component } from 'react';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Select, TextInput, WindowWidthStateContext, isZipValid } from '@talkspace/react-toolkit';
import { PromiseMessageTypeNames } from 'ts-promise-message';
import ReactFrameService from '@/auth/reactFrame/ReactFrameService';
import {
  getSSOValues,
  getDoseSpotPatient,
  postDoseSpotPatient,
  putDoseSpotPatient,
} from '../../../../../actions/DoseSpotActions';
import Submenu from '../../../../Reusable/Submenu/Submenu';
import Footer from '../../../../Reusable/Footer/Footer';
import AlertContainer from '../../../../Reusable/AlertContainer/AlertContainer';
import Button from '../../../../Reusable/Buttons/Button';
import { getOptionsByField } from '../../../../../utils/tsConfigsValues';
import { removeDiacritics } from '../../../../../utils/removeDiacritics';
import { replaceEmDash } from '../../../../../utils/replaceEmDash';
import { isIonic } from '@/auth/reactFrame/helpers';
import appConfig from '../../../../../utils/configs';

const doseSpotGenderOptionsConfigStyle = [
  { label: 'Male', value: 1 },
  { label: 'Female', value: 2 },
  { label: 'Unknown', value: 3 },
];
const getDoseSpotGenderConfigStyle = (gender) => {
  if (!gender) return null;
  if (gender.label.toLowerCase() === 'male') return 'Male';
  if (gender.label.toLowerCase() === 'female') return 'Female';
  return 'Unknown';
};
const isOfAgeForDoseSpot = (dob) => moment().diff(moment(dob, 'MM/DD/YYYY'), 'years') >= 18;

class DoseSpotPatientForm extends Component {
  constructor(props) {
    super(props);
    const {
      firstName,
      lastName,
      dateOfBirth,
      gender,
      streetAddress,
      city,
      state,
      zipcode,
      phoneNumber,
      clientUserID,
    } = this.props;

    const emergencyContact = this.props.emergencyContactByClientUserID[clientUserID] || {};
    const doseSpotPatient = this.props.doseSpotPatient || {};
    const finalDateOfBirth =
      doseSpotPatient.dateOfBirth || dateOfBirth
        ? moment(doseSpotPatient.dateOfBirth || dateOfBirth).format('MM/DD/YYYY')
        : '';
    const address2Value = doseSpotPatient.doseSpotPatientID
      ? doseSpotPatient.address2
      : emergencyContact.address2 || '';
    this.state = {
      firstName: {
        isValid: true,
        value: doseSpotPatient.firstName || firstName || emergencyContact.firstName || '',
      },
      lastName: {
        isValid: true,
        value: doseSpotPatient.lastName || lastName || emergencyContact.lastName || '',
      },
      dateOfBirth: {
        isValid: true,
        value: finalDateOfBirth,
      },
      gender: {
        isValid: true,
        value: doseSpotPatient.gender || getDoseSpotGenderConfigStyle(gender),
      },
      address: {
        isValid: true,
        value: doseSpotPatient.address || streetAddress || emergencyContact.address || '',
      },
      address2: {
        isValid: true,
        value: address2Value,
      },
      city: {
        isValid: true,
        value: doseSpotPatient.city || city || emergencyContact.city || '',
      },
      state: {
        isValid: true,
        value: doseSpotPatient.state || (state && state.label) || null,
      },
      zipcode: {
        isValid: true,
        value: doseSpotPatient.zipcode || zipcode || emergencyContact.zipcode || '',
      },
      primaryPhone: {
        isValid: true,
        value: doseSpotPatient.primaryPhone || phoneNumber || emergencyContact.phone || '',
      },
      showSpinner: false,
      showAlert: false,
    };

    this.createChildComponent = this.createChildComponent.bind(this);
    this.getPatientFormInputs = this.getPatientFormInputs.bind(this);
    this.getOnSaveChangeForFreeFormInput = this.getOnSaveChangeForFreeFormInput.bind(this);
    this.getOnSaveChangeForSingleSelect = this.getOnSaveChangeForSingleSelect.bind(this);
    this.generateDoseSpotPatientURL = this.generateDoseSpotPatientURL.bind(this);
    this.getFooterComponent = this.getFooterComponent.bind(this);
    this.getFooterChildren = this.getFooterChildren.bind(this);
    this.isPhoneNumberValid = this.isPhoneNumberValid.bind(this);
    this.isZipcodeValid = this.isZipcodeValid.bind(this);
    this.createPatient = this.createPatient.bind(this);
    this.updatePatient = this.updatePatient.bind(this);
    this.isFormValid = this.isFormValid.bind(this);
    this.handleNavigateTodosespot = this.handleNavigateTodosespot.bind(this);
    this.openWindowReference = null;
  }

  getAlertMessage() {
    const {
      address: { value: address },
    } = this.state;

    if (!isOfAgeForDoseSpot(this.state.dateOfBirth.value)) {
      return 'User needs to be 18 or older';
    }

    if (address.length > 35) {
      return 'Address must be 35 or fewer characters.';
    }

    return 'You must fill out the missing information to add the client to DoseSpot';
  }

  getFooterChildren() {
    const {
      history,
      doseSpotPatient: { doseSpotPatientID },
    } = this.props;
    const alertMessage = this.getAlertMessage();
    return this.state.showAlert
      ? [
          <AlertContainer
            key={1}
            title="Missing information"
            childComponents={[
              <Button
                title="Ok"
                key={2}
                clickHandler={() => this.setState({ showAlert: false })}
                style={{ paddingTop: 15 }}
              />,
            ]}
            body={alertMessage}
            containerStyle={{
              width: 355,
              border: 'none',
            }}
          />,
        ]
      : [
          <Button
            title="Cancel"
            isSecondary
            key={1}
            clickHandler={() => history.replace('clinical-tab')}
          />,
          doseSpotPatientID ? (
            <Button title="Update client" key={2} clickHandler={this.updatePatient} />
          ) : (
            <Button title="Add client" key={2} clickHandler={this.createPatient} />
          ),
        ];
  }

  getFooterComponent() {
    const {
      isUpdating,
      updateError,
      doseSpotPatient: { doseSpotPatientID },
    } = this.props;
    const footerStyle = this.state.showAlert
      ? {
          height: 151,
          flexDirection: 'column-reverse',
          paddingTop: 3,
        }
      : {};
    return (
      <Footer
        showSpinner={this.state.showSpinner}
        isUpdating={isUpdating}
        spinnerMessage={`Client successfully ${doseSpotPatientID ? 'updated' : 'added'}`}
        isError={updateError}
        footerStyle={footerStyle}
        childComponents={[...this.getFooterChildren()]}
      />
    );
  }

  getOnSaveChangeForFreeFormInput(fieldName) {
    return (event) =>
      this.setState({ [fieldName]: { ...this.state[fieldName], value: event.target.value } });
  }

  getOnSaveChangeForSingleSelect(fieldName) {
    return (value, _) => {
      this.setState({
        [fieldName]: {
          ...this.state[fieldName],
          value: value && value.label ? value.label : null,
        },
      });
    };
  }

  getPatientFormInputs() {
    const {
      firstName: { value: firstName },
      lastName: { value: lastName },
      dateOfBirth: { value: dateOfBirth },
      gender: { value: gender },
      address: { value: address },
      address2: { value: address2 },
      city: { value: city },
      state: { value: state },
      zipcode: { value: zipcode },
      primaryPhone: { value: primaryPhone },
    } = this.state;
    const stateObject = getOptionsByField('state').find((f) => f.label === state);

    const genderObject = doseSpotGenderOptionsConfigStyle.find((f) => f.label === gender);
    return [
      ['First name', 'firstName', firstName, null, 'freeFormInput'],
      ['Last name', 'lastName', lastName, null, 'freeFormInput'],
      [
        'Date of birth',
        'dateOfBirth',
        dateOfBirth,
        null,
        'maskedInput',
        '99/99/9999',
        '_',
        '__/__/____',
        'date',
      ],
      ['Street address', 'address', address, null, 'freeFormInput'],
      ['Street address line 2', 'address2', address2, null, 'freeFormInput'],
      ['City', 'city', city, null, 'freeFormInput'],
      ['ZIP code', 'zipcode', zipcode, null, 'freeFormInput'],
      ['State', 'state', stateObject, getOptionsByField('state'), 'singleSelect'],
      ['Gender', 'gender', genderObject, doseSpotGenderOptionsConfigStyle, 'singleSelect'],
      ['Phone number', 'primaryPhone', primaryPhone, null, 'freeFormInput'],
    ].map(
      ([
        placeholder,
        fieldName,
        value,
        options,
        type,
        mask,
        maskChar,
        emptyMask,
        textInputMaskType,
      ]) =>
        this.createChildComponent(
          placeholder,
          fieldName,
          value,
          options,
          type,
          mask,
          maskChar,
          emptyMask,
          textInputMaskType
        )
    );
  }

  handleNavigateTodosespot() {
    const { history } = this.props;
    setTimeout(() => {
      if (isIonic()) {
        window.location.href = this.generateDoseSpotPatientURL();
      } else if (ReactFrameService.instance().isInFrame()) {
        ReactFrameService.instance().sendAsyncMessage(PromiseMessageTypeNames.openBrowserWithURL, {
          url: this.generateDoseSpotPatientURL(),
        });
      } else if (this.openWindowReference) {
        this.openWindowReference.location = this.generateDoseSpotPatientURL();
        this.openWindowReference = null;
      }
      history.replace('clinical-tab');
    }, 2000);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isUpdating !== this.props.isUpdating) {
      if (nextProps.isUpdating) {
        this.setState({
          showSpinner: nextProps.isUpdating,
        });
      }
      if (!nextProps.isUpdating && !nextProps.updateError) {
        const {
          doseSpotPatient: { doseSpotPatientID },
          getDoseSpotPatient,
          getSSOValues,
        } = this.props;
        if (doseSpotPatientID) {
          // if patient ID exists
          getSSOValues()
            .then(() => this.handleNavigateTodosespot())
            .catch(() => {
              if (this.openWindowReference) this.openWindowReference.close();
            });
        } else {
          // otherwise get ID first
          getDoseSpotPatient()
            .then(getSSOValues)
            .then(() => this.handleNavigateTodosespot())
            .catch(() => {
              if (this.openWindowReference) this.openWindowReference.close();
            });
        }
      }
      if (!nextProps.isUpdating && nextProps.updateError) {
        setTimeout(() => {
          this.setState({
            showSpinner: false,
          });
        }, 3000);
      }
    }
  }

  createChildComponent(
    placeholder = '',
    fieldName = '',
    value,
    options,
    type,
    mask,
    maskChar,
    emptyMask,
    textInputMaskType
  ) {
    const showIndicatorBeforeClick = this.context.isMobile;
    if (type === 'freeFormInput') {
      return (
        <TextInput
          placeholder={placeholder}
          onBlur={this.getOnSaveChangeForFreeFormInput(fieldName)}
          value={value}
          isValid={this.state[fieldName].isValid}
        />
      );
    }
    if (type === 'singleSelect') {
      return (
        <Select
          placeholder={placeholder}
          options={options}
          value={value}
          onChange={this.getOnSaveChangeForSingleSelect(fieldName)}
          showIndicatorBeforeClick={showIndicatorBeforeClick}
        />
      );
    }
    return (
      <TextInput
        placeholder={placeholder}
        value={value}
        isValid={this.state[fieldName].isValid}
        onBlur={this.getOnSaveChangeForFreeFormInput(fieldName)}
        maskType={textInputMaskType}
      />
    );
  }

  createPatient() {
    if (!this.isFormValid()) return;
    const {
      firstName: { value: firstName },
      lastName: { value: lastName },
      dateOfBirth: { value: dateOfBirth },
      gender: { value: gender },
      address: { value: address },
      address2: { value: address2 },
      city: { value: city },
      state: { value: state },
      zipcode: { value: zipcode },
      primaryPhone: { value: primaryPhone },
    } = this.state;
    const { postDoseSpotPatient } = this.props;
    const dateOfBirthString = moment.utc(dateOfBirth).format();
    // trim the +1 international code
    const phoneNumber = primaryPhone.replace('+1', '');
    // Open window ahead of time
    if (!ReactFrameService.instance().isInFrame()) {
      this.openWindowReference = window.open();
    }
    postDoseSpotPatient({
      firstName: removeDiacritics(firstName),
      lastName: removeDiacritics(lastName),
      dateOfBirth: dateOfBirthString,
      gender,
      address: replaceEmDash(address),
      address2: address2 ? replaceEmDash(address2) : '',
      city,
      state,
      zipcode,
      primaryPhone: phoneNumber,
    });
  }

  generateDoseSpotPatientURL() {
    const {
      clinicID,
      clinicianID,
      ssoCode,
      encryptedClinicianID,
      doseSpotPatient: {
        firstName,
        lastName,
        dateOfBirth,
        gender,
        address,
        address2,
        city,
        state,
        zipcode,
        primaryPhone,
        doseSpotPatientID,
      },
    } = this.props;
    const urlEncodedSSOCode = encodeURIComponent(ssoCode);
    const urlEncodedEncryptedClinicianID = encodeURIComponent(encryptedClinicianID);
    const urlEncodedAddress = encodeURIComponent(address);
    const urlEncodedAddress2 = encodeURIComponent(address2);
    const phoneNumber = primaryPhone.replace('+1', '');
    const urlEncodedPhone = encodeURIComponent(phoneNumber);
    // trim the +1 international code

    const queryString = `?SingleSignOnClinicId=${clinicID}&SingleSignOnUserId=${clinicianID}&SingleSignOnPhraseLength=32&SingleSignOnCode=${urlEncodedSSOCode}&SingleSignOnUserIdVerify=${urlEncodedEncryptedClinicianID}&FirstName=${firstName}&LastName=${lastName}&DateOfBirth=${dateOfBirth}&Gender=${gender}&Address1=${urlEncodedAddress}&Address2=${urlEncodedAddress2}&City=${city}&State=${state}&ZipCode=${zipcode}&PrimaryPhone=${urlEncodedPhone}&PrimaryPhoneType=Cell&PatientId=${doseSpotPatientID}`;

    return `${appConfig.doseSpot.uiURL}${queryString}`;
  }

  isFormValid() {
    const {
      firstName,
      lastName,
      dateOfBirth,
      gender,
      address,
      city,
      state,
      zipcode,
      primaryPhone,
    } = this.state;
    const isAddressValid = !!address.value.length && address.value.length <= 35;
    const isValid =
      firstName.value.length &&
      lastName.value.length &&
      moment(dateOfBirth.value, 'MM/DD/YYYY').isValid() &&
      isOfAgeForDoseSpot(dateOfBirth.value) &&
      !!gender.value &&
      isAddressValid &&
      city.value.length &&
      state.value &&
      state.value.length &&
      this.isZipcodeValid() &&
      this.isPhoneNumberValid();
    this.setState({
      firstName: {
        ...firstName,
        isValid: !!firstName.value.length,
      },
      lastName: {
        ...lastName,
        isValid: !!lastName.value.length,
      },
      dateOfBirth: {
        ...dateOfBirth,
        isValid:
          moment(dateOfBirth.value, 'MM/DD/YYYY').isValid() &&
          isOfAgeForDoseSpot(dateOfBirth.value),
      },
      gender: {
        ...gender,
        isValid: !!gender.value,
      },
      address: {
        ...address,
        isValid: isAddressValid,
      },
      city: {
        ...city,
        isValid: !!city.value.length,
      },
      state: {
        ...state,
        isValid: !!state.value && !!state.value.length,
      },
      zipcode: {
        ...zipcode,
        isValid: this.isZipcodeValid(),
      },
      primaryPhone: {
        ...primaryPhone,
        isValid: this.isPhoneNumberValid(),
      },
      showAlert: !isValid,
    });

    return isValid;
  }

  isPhoneNumberValid() {
    const primaryPhone = this.state.primaryPhone.value;
    if (!primaryPhone) return false;
    const phoneNumberDigitString = primaryPhone.match(/\d+/g).join('');
    return phoneNumberDigitString.length >= 10 && phoneNumberDigitString.length <= 15;
  }

  isZipcodeValid() {
    if (!this.state.zipcode.value) return false;
    return isZipValid(this.state.zipcode.value, 'US');
  }

  updatePatient() {
    if (!this.isFormValid()) return;
    const {
      firstName: { value: firstName },
      lastName: { value: lastName },
      dateOfBirth: { value: dateOfBirth },
      gender: { value: gender },
      address: { value: address },
      address2: { value: address2 },
      city: { value: city },
      state: { value: state },
      zipcode: { value: zipcode },
      primaryPhone: { value: primaryPhone },
    } = this.state;
    const { putDoseSpotPatient } = this.props;
    const dateOfBirthString = moment.utc(dateOfBirth).format();
    // Open window ahead of time
    if (!ReactFrameService.instance().isInFrame()) {
      this.openWindowReference = window.open();
    }
    putDoseSpotPatient({
      firstName,
      lastName,
      dateOfBirth: dateOfBirthString,
      gender,
      address,
      address2: address2 || '',
      city,
      state,
      zipcode,
      primaryPhone,
    });
  }

  render() {
    return (
      <Submenu
        childComponents={[...this.getPatientFormInputs()]}
        footerComponent={this.getFooterComponent()}
        bodyStyle={{
          paddingTop: 25,
          paddingLeft: 35,
          paddingRight: 35,
        }}
        title={
          this.props.doseSpotPatient.doseSpotPatientID
            ? 'Update DoseSpot patient'
            : 'Add client to DoseSpot'
        }
        prevRoute="clinical-tab"
      />
    );
  }
}

DoseSpotPatientForm.contextType = WindowWidthStateContext;
const mapStateToProps = (state) => {
  return {
    firstName: state.customerInformation.firstName,
    lastName: state.customerInformation.lastName,
    dateOfBirth: state.customerInformation.dateOfBirth,
    gender: state.customerInformation.gender,
    streetAddress: state.customerInformation.streetAddress,
    address2: state.customerInformation.address2,
    city: state.customerInformation.city,
    state: state.customerInformation.state,
    zipcode: state.customerInformation.zipcode,
    phoneNumber: state.customerInformation.phoneNumber,
    clientUserID: state.customerInformation.clientUserID,
    roomID: state.room.roomID,
    doseSpotPatient: state.doseSpot.doseSpotPatient,
    isUpdating: state.doseSpot.isUpdating,
    updateError: state.doseSpot.updateError,
    emergencyContactByClientUserID: state.emergencyContact.emergencyContactByClientUserID,
    encryptedClinicianID: state.doseSpot.encryptedClinicianID,
    clinicID: state.doseSpot.clinicID,
    clinicianID: state.doseSpot.clinicianID,
    ssoCode: state.doseSpot.ssoCode,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { getSSOValues, getDoseSpotPatient, postDoseSpotPatient, putDoseSpotPatient },
    dispatch
  );

const DoseSpotPatientFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DoseSpotPatientForm);

export default DoseSpotPatientFormContainer;
