import moment from 'moment';
import * as types from '../constants/DoseSpotConstants';
import apiWrapper from '../utils/apiWrapper';
import apiHelper from '../utils/api';

export function requestDoseSpotPatient(clientUserID) {
  return {
    type: types.REQUEST_DOSESPOT_PATIENT,
    clientUserID,
  };
}

export function requestDoseSpotPatientError(error) {
  return {
    type: types.REQUEST_DOSESPOT_PATIENT_ERROR,
    error,
  };
}

export function doseSpotPatientNotFound(error) {
  return {
    type: types.DOSESPOT_PATIENT_NOT_FOUND,
    error,
  };
}

export function receiveDoseSpotPatient({
  firstName,
  lastName,
  dateOfBirth,
  gender,
  address,
  address2,
  city,
  state,
  zipcode,
  primaryPhone,
  doseSpotPatientID,
}) {
  return {
    type: types.RECEIVE_DOSESPOT_PATIENT,
    firstName,
    lastName,
    dateOfBirth,
    gender,
    address,
    address2,
    city,
    state,
    zipcode,
    primaryPhone,
    doseSpotPatientID,
  };
}

export function createDoseSpotPatient(clientUserID) {
  return {
    type: types.CREATE_DOSESPOT_PATIENT,
    clientUserID,
  };
}

export function createDoseSpotPatientSuccess(
  clientUserID,
  {
    firstName,
    lastName,
    dateOfBirth,
    gender,
    address,
    city,
    state,
    zipcode,
    primaryPhone,
    doseSpotPatientID,
  }
) {
  return {
    type: types.CREATE_DOSESPOT_PATIENT_SUCCESS,
    firstName,
    lastName,
    dateOfBirth,
    gender,
    address,
    city,
    state,
    zipcode,
    primaryPhone,
    clientUserID,
    doseSpotPatientID,
  };
}

export function updateDoseSpotPatientSuccess(
  clientUserID,
  {
    firstName,
    lastName,
    dateOfBirth,
    gender,
    address,
    address2,
    city,
    state,
    zipcode,
    primaryPhone,
  }
) {
  return {
    type: types.UPDATE_DOSESPOT_PATIENT_SUCCESS,
    firstName,
    lastName,
    dateOfBirth,
    gender,
    address,
    address2,
    city,
    state,
    zipcode,
    primaryPhone,
    clientUserID,
  };
}

export function updateDoseSpotPatientError(error) {
  return {
    type: types.UPDATE_DOSESPOT_PATIENT_ERROR,
    error,
  };
}

export function updateDoseSpotPatient(clientUserID) {
  return {
    type: types.UPDATE_DOSESPOT_PATIENT,
    clientUserID,
  };
}

export function requestSSOValues() {
  return {
    type: types.REQUEST_DOSESPOT_SSO_VALUES,
  };
}

export function receiveSSOValues({ clinicID, ssoCode, encryptedClinicianID, clinicianID }) {
  return {
    type: types.RECEIVE_DOSESPOT_SSO_VALUES,
    clinicID,
    ssoCode,
    encryptedClinicianID,
    clinicianID,
  };
}

export function requestSSOValuesError(error) {
  return {
    type: types.REQUEST_DOSESPOT_SSO_VALUES_ERROR,
    error,
  };
}

export const getDoseSpotPatient = () => (dispatch, getState) => {
  const {
    customerInformation: { clientUserID },
  } = getState();
  dispatch(requestDoseSpotPatient(clientUserID));
  return apiWrapper
    .get(`${apiHelper().apiEndpoint}/v2/clients/${clientUserID}/dosespot-patient`)
    .then((response) => {
      const genderEnum = genderEnumsAndIDs.find(({ id }) => id === response.data.data.gender).text;
      const dateOfBirthString = moment.utc(response.data.data.dateOfBirth).format('MM/DD/YYYY');
      return dispatch(
        receiveDoseSpotPatient({
          ...response.data.data,
          gender: genderEnum,
          dateOfBirth: dateOfBirthString,
        })
      );
    })
    .catch((error) => {
      if (error.message === '404') {
        return dispatch(doseSpotPatientNotFound(error));
      }
      return dispatch(requestDoseSpotPatientError(error));
    });
};

const genderEnumsAndIDs = [
  { text: 'Male', id: 1 },
  { text: 'Female', id: 2 },
  { text: 'Unknown', id: 3 },
];

export const postDoseSpotPatient = (patient) => (dispatch, getState) => {
  const {
    customerInformation: { clientUserID },
  } = getState();
  dispatch(createDoseSpotPatient(clientUserID));
  const genderID = genderEnumsAndIDs.find(({ text }) => text === patient.gender).id;
  return apiWrapper
    .post(`${apiHelper().apiEndpoint}/v2/clients/${clientUserID}/dosespot-patient`, {
      ...patient,
      gender: genderID,
    })
    .then((response) => {
      const dateOfBirthString = moment.utc(response.data.data.dateOfBirth).format('MM/DD/YYYY');
      return dispatch(
        createDoseSpotPatientSuccess(clientUserID, {
          ...response.data.data,
          dateOfBirth: dateOfBirthString,
        })
      );
    })
    .catch((error) => dispatch(updateDoseSpotPatientError(error)));
};

export const putDoseSpotPatient = (patient) => (dispatch, getState) => {
  const {
    customerInformation: { clientUserID },
  } = getState();
  dispatch(updateDoseSpotPatient(clientUserID));
  const genderID = genderEnumsAndIDs.find(({ text }) => text === patient.gender).id;
  return apiWrapper
    .patch(`${apiHelper().apiEndpoint}/v2/clients/${clientUserID}/dosespot-patient`, {
      ...patient,
      gender: genderID,
    })
    .then(() => {
      const dateOfBirthString = moment.utc(patient.dateOfBirth).format('MM/DD/YYYY');
      return dispatch(
        updateDoseSpotPatientSuccess(clientUserID, { ...patient, dateOfBirth: dateOfBirthString })
      );
    })
    .catch((error) => dispatch(updateDoseSpotPatientError(error)));
};

export const getSSOValues = () => (dispatch) => {
  dispatch(requestSSOValues());
  return apiWrapper
    .get(`${apiHelper().apiEndpoint}/v2/dosespot-clinicians/access`)
    .then((response) => dispatch(receiveSSOValues(response.data.data)))
    .catch((error) => dispatch(requestSSOValuesError(error)));
};
