import {
  EmotionThemeProvider,
  getSpacing,
  Spinner,
  styled,
  TextDS,
  useWindowWidth,
  View,
} from '@talkspace/react-toolkit';

import GraphView from './GraphView';
import WeekTable from './WeekTable';
import Footer from '../../../Footer/Footer';
import useQueryClinicalHours from '../queries/useQueryClinicalHours';
import { getUserData } from '../../../../utils/token';
import { formatLastUpdatedAt } from '../utils/date';
import QuarterView from './QuarterView';

const MainView = styled(View)(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      flexDirection: 'column',
      gap: getSpacing(isMobile ? 'space500' : 'space600', false),
      padding: getSpacing(isMobile ? 'space200' : 'space500'),
    };
  }
);

const TitleView = styled(View)(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      flexDirection: isMobile ? 'column' : 'row',
      gap: getSpacing(isMobile ? 'space050' : 'space300', false),
      alignItems: 'baseline',
    };
  }
);

const ClinicalHours = () => {
  const { isMobile } = useWindowWidth();

  const therapistID = getUserData().id;

  const { data, isLoading } = useQueryClinicalHours(therapistID);

  if (isLoading || !data) {
    return <Spinner />;
  }

  return (
    <EmotionThemeProvider version="2.0.0">
      <MainView>
        <TitleView>
          <TextDS variant={isMobile ? 'headingXl' : 'heading2xl'}>Clinical engagement hours</TextDS>

          {data.lastUpdatedAt && (
            <TextDS variant="bodySm" colorRole="textSubtlest">
              Last updated on {formatLastUpdatedAt(data.lastUpdatedAt)}
            </TextDS>
          )}
        </TitleView>

        <QuarterView />

        <GraphView />

        <WeekTable />
      </MainView>

      <Footer hidden={false} />
    </EmotionThemeProvider>
  );
};

export default ClinicalHours;
