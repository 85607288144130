import { combineReducers } from 'redux';
import { RESET_INITIAL_ROOM_STATE } from '../constants/RoomConstants';
import availability from './availability';
import auth from './auth';
import customerInformation from './customerInformation';
import customerList from './customerList';
import doseSpot from './doseSpot';
import invite from './invites';
import quickMatch from './quickMatch';
import informedConsent from './informedConsent';
import emergencyContact from './emergencyContact';
import videoCredits from './videoCredits';
import videoCallHistory from './videoCallHistory';
import matching from './matching';
import marketing from './marketing';
import clinicalInformation from './clinicalInformation';
import medicalInformation from './medicalInformation';
import outcomeMeasures from './outcomeMeasures';
import treatmentPlanner from './treatmentPlanner';
import room from './room';
import iframe from './iframe';
import journey from './journey';
import booking from './booking';
import diagnosticProfile from './diagnosticProfile';
import roomCase from './roomCase';
import paymentReports from './paymentReports';

const appReducer = combineReducers({
  availability,
  auth,
  customerInformation,
  medicalInformation,
  customerList,
  doseSpot,
  informedConsent,
  emergencyContact,
  videoCredits,
  videoCallHistory,
  invite,
  quickMatch,
  matching,
  marketing,
  clinicalInformation,
  diagnosticProfile,
  outcomeMeasures,
  treatmentPlanner,
  room,
  iframe,
  journey,
  booking,
  roomCase,
  paymentReports,
});

const rootReducer = (state, action) => {
  // https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store
  if (action.type === RESET_INITIAL_ROOM_STATE) return appReducer(undefined, action);
  return appReducer(state, action);
};

export default rootReducer;
