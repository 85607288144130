export const REQUEST_DOSESPOT_PATIENT = 'REQUEST_DOSESPOT_PATIENT';
export const REQUEST_DOSESPOT_PATIENT_ERROR = 'REQUEST_DOSESPOT_PATIENT_ERROR';
export const RECEIVE_DOSESPOT_PATIENT = 'RECEIVE_DOSESPOT_PATIENT';
export const DOSESPOT_PATIENT_NOT_FOUND = 'DOSESPOT_PATIENT_NOT_FOUND';
export const CREATE_DOSESPOT_PATIENT = 'CREATE_DOSESPOT_PATIENT';
export const CREATE_DOSESPOT_PATIENT_SUCCESS = 'CREATE_DOSESPOT_PATIENT_SUCCESS';
export const UPDATE_DOSESPOT_PATIENT = 'UPDATE_DOSESPOT_PATIENT';
export const UPDATE_DOSESPOT_PATIENT_SUCCESS = 'UPDATE_DOSESPOT_PATIENT_SUCCESS';
export const UPDATE_DOSESPOT_PATIENT_ERROR = 'UPDATE_DOSESPOT_PATIENT_ERROR';
export const REQUEST_DOSESPOT_SSO_VALUES = 'REQUEST_DOSESPOT_SSO_VALUES';
export const REQUEST_DOSESPOT_SSO_VALUES_ERROR = 'REQUEST_DOSESPOT_SSO_VALUES_ERROR';
export const RECEIVE_DOSESPOT_SSO_VALUES = 'RECEIVE_DOSESPOT_SSO_VALUES';
