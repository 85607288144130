import {
  Preferences,
  Table,
  TouchableView,
  useEmotionTheme,
  useWindowWidthState,
  View,
} from '@talkspace/react-toolkit';

import { useEffect, useState } from 'react';
import { dashboardGetHeaderProps } from 'components/Dashboard/DashboardTable';
import useMutationUpdateClientCapacity from 'hooks/availability/useMutationUpdateClientCapacity';
import useQueryClientCapacity from 'hooks/availability/useQueryClientCapacity';
import useQueryAdminConfigValue from 'hooks/useQueryUsersAdminConfigValue';
import { TherapistType } from 'ts-frontend/entities/Therapist';
import ClearIcon from 'components/Icons/ClearIcon';

import useQueryClaimableRooms from 'hooks/growCaseload/useQueryClaimableRooms';
import { useFlags } from 'launchDarkly/FlagsProvider';

import { CountdownSpinner } from '@talkspace/react-toolkit/src/components/icons';
import styled from '../../../core/styled';
import { getUserData } from '../../../utils/token';

import NewClientAvailabilityBar from '../NewClientAvailabilityBar/NewClientAvailabilityBar';
import PreferencesModal, { WaitingClientsFilter } from './Modals/PreferencesModal';
import ClientInformationModal from './Modals/ClientInformationModal';
import { ClaimableRoom, CLIENT_ALREADY_CLAIMED_STATUSES, SuccessfulClaimClientInfo } from './types';
import ViewClientButton from './ViewClientButton';
import ClaimRoomSuccessModal from './Modals/ClaimRoomSuccessModal';
import AlreadyClaimedModal from './Modals/AlreadyClaimedModal';
import useCountdownTimer from '../hooks/useCountdownTimer';
import Footer from '../../Footer/Footer';
import EmptyState from './EmptyState';
import ErrorModal from './Modals/ErrorModal';
import Analytics from './Analytics';

const Button = styled(TouchableView)(({ theme: { colors } }) => {
  return {
    color: colors.accessibilityGreenDark,
    fontWeight: 700,
    fontSize: 15,
  };
});

const PreferencesButtonWrapper = styled(Button)(({ theme: { colors } }) => {
  return {
    '&:hover': {
      backgroundColor: colors.aliceBlue,
    },
    '&:active': {
      backgroundColor: colors.permaSolitude,
    },
    backgroundColor: colors.permaPhantomWhite,
    alignItems: 'center',
    borderRadius: 5,
    paddingRight: 15,
    paddingLeft: 15,
    fontWeight: 500,
    lineHeight: '36px',
    height: 36,
    color: colors.permaBlueStoneNew,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    position: 'relative',
    gap: 4,
  };
});

const PreferencesButton = ({
  setFilters,
  setShowPreferencesModal,
  preferences,
}: {
  setFilters: (filters: Array<WaitingClientsFilter>) => void;
  setShowPreferencesModal: (show: boolean) => void;
  preferences: Array<WaitingClientsFilter>;
}) => {
  const { colors } = useEmotionTheme();

  useEffect(() => {
    if (preferences?.length !== 1) {
      setFilters([]);
    } else {
      setFilters(preferences);
    }
  }, [preferences, setFilters]);

  const therapistID: number = getUserData().id;

  return (
    <PreferencesButtonWrapper
      onPress={() => {
        Analytics.trackModalActionEvent({
          modal: 'Preferences modal',
          action: 'Show',
          therapistID,
        });
        setShowPreferencesModal(true);
      }}
      style={{
        border: `1px solid ${colors.permaBlueStoneNew}`,
        width: preferences?.length ? 151 : 131,
      }}
    >
      <View>
        <Preferences />
      </View>
      <View style={{ fontSize: 14 }}>Preferences</View>
    </PreferencesButtonWrapper>
  );
};

const MobileInCellHeader = styled(View)(({ theme: { colors } }) => {
  return {
    fontSize: 11,
    marginRight: 25,
    lineHeight: '13px',
    color: colors.permaFiord,
  };
});

const GrowCaseloadWrapper = styled(View)(
  ({
    theme: {
      colors,
      window: { isMobile },
    },
  }) => {
    return {
      padding: isMobile ? '0 15px' : '0 30px',
      marginTop: isMobile ? 25 : 40,
      fontFamily: 'Roboto',
      color: colors.permaOuterSpaceNew,
      lineHeight: '21px',
    };
  }
);

const PreferencesDescriptionSection = styled(View)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: 50,
  marginBottom: 10,
});

const RefreshingCounterSection = styled(View)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  lineHeight: '36px',
});

const FiltersSection = styled(View)({
  display: 'flex',
  flexDirection: 'row',
  lineHeight: '30px',
  marginTop: 10,
});

const Filter = styled(View)(({ theme: { colors } }) => {
  return {
    backgroundColor: colors.permaSchiavaBlue,
    borderRadius: '45px',
    height: 30,
    paddingRight: 10,
    paddingLeft: 10,
    marginLeft: 5,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: 14,
    fontFamily: 'Roboto',
    fontWeight: 500,
    color: '#FFFFFF',
  };
});

const ClearButton = styled(TouchableView)(({ theme: { colors } }) => {
  return {
    marginLeft: 15,
    paddingBottom: 0,
    textDecorationLine: 'underline',
    color: colors.permaBlueStoneNew,
    fontWeight: 700,
    fontSize: '15px',
  };
});

interface GrowCaseloadProps {
  therapistType?: TherapistType;
  isError: boolean;
}

const GrowCaseload = ({ therapistType, isError }: GrowCaseloadProps) => {
  const { isMobile, isLarge } = useWindowWidthState();
  const { colors } = useEmotionTheme();

  const therapistID: number = getUserData().id;

  const { data: clientCapacity, isLoading: isClientCapacityLoading } =
    useQueryClientCapacity(therapistID);

  const { mutate: updateClientCapacity, isLoading: isClientCapacityUpdating } =
    useMutationUpdateClientCapacity();

  const { data: dailyCaseLoadOptions, isLoading: isDailyCaseLoadOptionsLoading } =
    useQueryAdminConfigValue<Record<string, number>>({
      key: 'provider_daily_caseload_options',
      json: true,
    });

  const [isAvailabilityBarOpen, setIsAvailabilityBarOpen] = useState<boolean>(false);
  const [showAnimatedCheck, setShowAnimatedCheck] = useState<boolean>(false);
  const [shouldResetTimer, setShouldResetTimer] = useState<boolean>(false);

  const handleOpenAvailabilityBar = (value: boolean) => {
    setIsAvailabilityBarOpen(value);
  };

  const handleShowAnimatedCheck = (value: boolean) => {
    setShowAnimatedCheck(value);
  };

  const [preferences, setPreferences] = useState<Array<WaitingClientsFilter>>([]);

  const { data: claimableRooms = [], refetch: refetchClaimableRooms } = useQueryClaimableRooms({
    therapistID,
    preferences,
    onSuccess: () => {
      setShouldResetTimer(true);
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filters, setFilters] = useState<Array<WaitingClientsFilter>>([]);

  const [claimableRoom, setClaimableRoom] = useState<ClaimableRoom | null>(null);

  const [successfulyClaimedClientDetails, setSuccessfulyClaimedClientDetails] =
    useState<SuccessfulClaimClientInfo | null>(null);
  const [isClaimRoomFailure, setIsClaimRoomFailure] = useState<boolean>(false);

  const [showPreferencesModal, setShowPreferencesModal] = useState<boolean>(false);

  const [isGeneralError, setIsGeneralError] = useState<boolean>(false);

  const { growCaseloadAttributes } = useFlags();

  const refetchClaimableRoomsCountdownTimer = useCountdownTimer({
    shouldRepeat: true,
    timeInSeconds: growCaseloadAttributes.refetchSeconds,
    onTimeIsUp: () => {
      refetchClaimableRooms();
    },
    shouldResetTimer,
    handleResetTimer: (value: boolean) => setShouldResetTimer(value),
  });

  useEffect(() => {
    if (claimableRoom && therapistID) {
      Analytics.trackClaimableClientInformationViewedEvent({
        roomID: claimableRoom.roomID,
        therapistID,
        presentingProblems: claimableRoom.presentingProblem.toString(),
        clientGender: claimableRoom.clientGender,
        age: claimableRoom.clientAge,
        preference: claimableRoom.preference,
        state: claimableRoom.state || '',
        sessions: claimableRoom.numberOfSessions || '',
        isTreatmentIntakeCompleted: claimableRoom.treatmentIntake,
      });
    }
  }, [claimableRoom, therapistID]);

  const desktopColumns = [
    {
      Header: 'Age',
      id: 'age',
      Cell: ({
        row: {
          original: { clientAge },
        },
      }: any) => clientAge,
    },
    {
      Header: 'Client gender',
      id: 'clientGender',
      Cell: ({
        row: {
          original: { clientGender },
        },
      }: any) => clientGender,
    },
    {
      Header: 'Presenting problem',
      id: 'presentingProblem',
      Cell: ({
        row: {
          original: { presentingProblem },
        },
      }: any) => presentingProblem[0] || '',
    },
    {
      Header: 'Preference',
      id: 'preference',
      Cell: ({
        row: {
          original: { preference },
        },
      }: any) => preference || '',
    },
    {
      Header: '',
      id: 'viewButton',
      Cell: ({ row: { original } }: any) => (
        <View style={{ position: 'relative' }}>
          <ViewClientButton
            onButtonPress={() => {
              setClaimableRoom(original);
            }}
          />
        </View>
      ),
    },
  ];

  const mobileColumns = [
    {
      Header: 'MobileClientData',
      id: 'gender & age',
      Cell: ({
        row: {
          original: { clientAge, clientGender, preference, presentingProblem },
        },
      }: any) => (
        <>
          <View style={{ display: 'flex', flexDirection: 'row', marginBottom: 8 }}>
            <View>
              <MobileInCellHeader>Gender & age</MobileInCellHeader>
              <View style={{ textTransform: 'capitalize' }}>
                {clientAge}, {clientGender}
              </View>
            </View>
            <View>
              <MobileInCellHeader>Preference</MobileInCellHeader>
              <View>{preference || ''}</View>
            </View>
          </View>
          <MobileInCellHeader>Presenting problem</MobileInCellHeader>
          <View style={{ textTransform: 'capitalize' }}>{presentingProblem[0] || ''}</View>
        </>
      ),
    },
    {
      Header: '',
      id: 'viewButton',
      Cell: ({ row: { original } }: any) => (
        <View style={{ position: 'relative' }}>
          <ViewClientButton
            onButtonPress={() => {
              setClaimableRoom(original);
            }}
          />
        </View>
      ),
    },
  ];

  useEffect(() => {
    refetchClaimableRooms();
  }, [preferences, refetchClaimableRooms]);

  const confirmationFailure = (status: number) => {
    if (CLIENT_ALREADY_CLAIMED_STATUSES.includes(status)) {
      setIsClaimRoomFailure(true);
      Analytics.trackModalActionEvent({
        modal: 'Already claimed modal',
        action: 'Show',
        therapistID,
        roomID: claimableRoom?.roomID,
      });
    } else {
      setIsGeneralError(true);
      Analytics.trackModalActionEvent({
        modal: 'General error modal',
        action: 'Show',
        therapistID,
        roomID: claimableRoom?.roomID,
      });
    }
  };

  return (
    <GrowCaseloadWrapper>
      <PreferencesModal
        setShowPreferencesModal={setShowPreferencesModal}
        showPreferencesModal={showPreferencesModal}
        preferences={preferences}
        setPreferences={setPreferences}
      />
      <ClientInformationModal
        confirmationSuccess={setSuccessfulyClaimedClientDetails}
        confirmationFailure={(status: number) => {
          confirmationFailure(status);
        }}
        onBackdropPress={() => {
          setClaimableRoom(null);
        }}
        hide={!claimableRoom}
        clientInformation={claimableRoom!}
      />
      <ClaimRoomSuccessModal
        onBackdropPress={() => {
          Analytics.trackModalActionEvent({
            modal: 'Claim room success modal',
            action: 'Hide',
            therapistID,
            roomID: claimableRoom?.roomID,
          });
          setSuccessfulyClaimedClientDetails(null);
          refetchClaimableRooms();
        }}
        hide={!successfulyClaimedClientDetails}
        clientDetails={successfulyClaimedClientDetails}
      />
      <AlreadyClaimedModal
        onBackdropPress={() => {
          Analytics.trackModalActionEvent({
            modal: 'Already claimed modal',
            action: 'Hide',
            therapistID,
            roomID: claimableRoom?.roomID,
          });
          setIsClaimRoomFailure(false);
          refetchClaimableRooms();
        }}
        hide={!isClaimRoomFailure}
      />
      <ErrorModal
        onBackdropPress={() => {
          Analytics.trackModalActionEvent({
            modal: 'General error modal',
            action: 'Hide',
            therapistID,
            roomID: claimableRoom?.roomID,
          });
          setIsGeneralError(false);
          refetchClaimableRooms();
        }}
        hide={!isGeneralError}
      />
      <View
        style={{
          fontSize: 18,
          fontWeight: 400,
          marginBottom: 18,
        }}
      >
        Grow your caseload
      </View>
      <View style={{ fontSize: 18, fontWeight: 700, marginBottom: 7 }}>Automatic matching</View>
      <View style={{ fontSize: 14, lineHeight: '18px', marginBottom: 10 }}>
        Select the maximum amount of clients you want per day. When possible, we’ll match you with
        clients based on your live session availability.
      </View>

      {clientCapacity?.dailyCaseLoad && dailyCaseLoadOptions && therapistType && (
        <View
          style={{ padding: 0, marginBottom: 30 }}
          className="col-xs-12 col-xl-10 col-xl-push-1"
        >
          <NewClientAvailabilityBar
            dailyCaseLoadOptions={dailyCaseLoadOptions}
            providerCapacity={
              dailyCaseLoadOptions && dailyCaseLoadOptions[clientCapacity.dailyCaseLoad]
            }
            onSelectionPress={(value) => {
              updateClientCapacity({ therapistID, dailyCaseLoad: value });
            }}
            isCapacityUpdating={isClientCapacityUpdating}
            onSuccess={() => {}}
            isError={isError}
            handleOpen={handleOpenAvailabilityBar}
            isOpen={isAvailabilityBarOpen}
            shouldShowAnimatedCheck={showAnimatedCheck}
            handleShowAnimatedCheck={handleShowAnimatedCheck}
            therapistType={therapistType}
            isLoading={isDailyCaseLoadOptionsLoading || isClientCapacityLoading}
          />
        </View>
      )}
      <View style={{ display: 'flex', flexDirection: 'row', fontWeight: 700, marginBottom: 7 }}>
        <View style={{ marginRight: 6, fontSize: 18 }}>Choose from waiting clients</View>
      </View>
      <PreferencesDescriptionSection>
        <View style={{ flexShrink: 1, minWidth: 200, overflow: 'auto' }}>
          Explore the waitlist and select clients that fit your preferences. Adding these clients to
          your caseload won’t count towards your automatic matching limit.
        </View>
        {!isMobile && (
          <PreferencesButton
            setFilters={setFilters}
            setShowPreferencesModal={setShowPreferencesModal}
            preferences={preferences}
          />
        )}
      </PreferencesDescriptionSection>
      <RefreshingCounterSection>
        <View row align="center">
          <View row style={{ color: colors.purple600 }}>
            Refreshing in:{' '}
            <View style={{ margin: '0 5px', fontWeight: 500 }}>
              {refetchClaimableRoomsCountdownTimer}
            </View>
          </View>
          <CountdownSpinner
            secondsRemaining={refetchClaimableRoomsCountdownTimer}
            totalSeconds={growCaseloadAttributes.refetchSeconds}
          />
        </View>

        {isMobile && (
          <PreferencesButton
            setFilters={setFilters}
            setShowPreferencesModal={setShowPreferencesModal}
            preferences={preferences}
          />
        )}
      </RefreshingCounterSection>

      {!!preferences.length && (
        <FiltersSection>
          <View
            style={{
              fontSize: 14,
              fontWeight: 500,
              color: colors.permaOuterSpaceNew,
            }}
          >
            Filtering by:
          </View>
          {preferences.map((it) => (
            <Filter>
              <View style={{ marginRight: 6 }}>{it}</View>
              <TouchableView
                onPress={() => {
                  setPreferences(preferences.filter((p) => p !== it));
                }}
                style={{ color: 'white' }}
              >
                <ClearIcon />
              </TouchableView>
            </Filter>
          ))}

          <ClearButton onPress={() => setPreferences([])}>Clear all</ClearButton>
        </FiltersSection>
      )}

      <View>
        {!!claimableRooms && (
          <Table
            hideHeader={isMobile}
            getHeaderProps={dashboardGetHeaderProps(colors)}
            getCellProps={(props) => {
              return {
                style: {
                  cursor: 'default',
                  verticalAlign: 'middle',
                  ...(isMobile && {
                    verticalAlign: 'top',
                    ...(props?.row?.index === 0 && { borderTop: 'none' }),
                  }),
                  ...(isLarge && { verticalAlign: 'top' }),
                },
              };
            }}
            columns={isMobile ? mobileColumns : desktopColumns}
            data={claimableRooms as any}
            isLoading={false}
            initialState={{ pageIndex: 0, pageSize: 10 }}
            Empty={<EmptyState />}
          />
        )}
      </View>
      <Footer hidden={false} />
    </GrowCaseloadWrapper>
  );
};

export default GrowCaseload;
