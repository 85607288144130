import { Component } from 'react';
import {
  Row,
  Col,
  Panel,
  FormGroup,
  FormControl,
  Button,
  Tooltip,
  OverlayTrigger,
} from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import { getUserData } from '../../../utils/token';
import apiWrapper from '../../../utils/apiWrapper';
import apiHelper from '../../../utils/api';
import { therapistImageEndpoint } from '../../../utils/resourceHelper';
import '../FormHeader/FormHeader.css';
import '../Account.css';
import 'react-toastify/dist/ReactToastify.min.css';
import profileDefault from '../../Reusable/NavBarAccountDropDownMenu/profile_default.svg';
import { getOptionsByField } from '../../../utils/tsConfigsValues';
import { getAdminConfigValue } from '../../../actions/AuthActions';

class TherapistProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userID: getUserData().id,
      isLoaded: false,
      disableSave: false,
      articleLinkError: false,
      bioEmpty: false,
      bioTooShort: false,
      bioTooLong: false,
      minBioChar: 299,
      maxBioChar: 620,
      charsLeft: 620,
      isEdit: false,
      profile: {},
      profileSave: '',
      articleLinkSave: '',
      websiteDirectLinkURL: `${apiHelper().homePageEndpoint}/room/`,
      isEthnicitySelected: true,
    };
    this.getProfile = this.getProfile.bind(this);
    this.getProfileImg = this.getProfileImg.bind(this);
    this.toggleFormEdit = this.toggleFormEdit.bind(this);
    this.getCharCount = this.getCharCount.bind(this);
    this.handleBioChange = this.handleBioChange.bind(this);
    this.handleBioBlur = this.handleBioBlur.bind(this);
    this.saveForm = this.saveForm.bind(this);
    this.articleLinkChange = this.articleLinkChange.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
    this.verifyPsychologyTodayId = this.verifyPsychologyTodayId.bind(this);
    this.handleDirectoryParticipationChange = this.handleDirectoryParticipationChange.bind(this);
  }

  componentDidMount() {
    this.getProfile();
    this.isRegistrationFlagOn();
  }

  getCharCount() {
    const { length: charCount } = this.refs.bio.props.children.props.value;
    const charsLeft = this.state.maxBioChar - charCount;
    this.setState({ charsLeft });
  }

  getProfile() {
    const { id: userID } = getUserData();

    return apiWrapper
      .get(`${apiHelper().apiEndpoint}/api/v1/therapist/${userID}/profile-info`)
      .then((response) => {
        this.setState({
          profile: response.data.data,
          isLoaded: true,
          bioEmpty: response.data.data.bio.length === 0,
          isEthnicitySelected: !!response.data.data.ethnicity,
        });
        this.props.setIsSectionLoaded({ profile: true });
      })
      .catch((err) => {
        this.setState({ error: err });
      });
  }

  getProfileImg() {
    const { id: userID } = getUserData();
    const img = `${therapistImageEndpoint()}/${userID}.jpg`;
    return img;
  }

  articleLinkChange({ target: { value, classList } }) {
    // keep state of complex object
    const { profile: updateProfile } = this.state;
    updateProfile.psychologyTodayId = value;
    this.setState({ profile: updateProfile });
    if (isNaN(value)) {
      this.setState({ articleLinkError: true, disableSave: true });
      classList.add('invalid-field');
    } else {
      this.setState({ articleLinkError: false, disableSave: false });
      classList.remove('invalid-field');
    }
  }

  cancelEdit() {
    this.toggleFormEdit();
    const { profile: updatedProfile, profileSave, articleLinkSave } = this.state;
    updatedProfile.bio = profileSave;
    updatedProfile.psychologyTodayId = articleLinkSave;
    this.setState({ profile: updatedProfile, isEthnicitySelected: !!updatedProfile.ethnicity });
  }

  /**
   * Returns -1 when a should be before b, and vice versa.
   * The order is alphabetical 'Choose not to respond' last,
   * 'Other racial or ethnic background' before that, and
   * 'Biracial or multiracial' before that
   * @param {*} a first item to compare
   * @param {*} b second item to compare
   */
  compareEthnicites = (a, b) =>
    a.value === 'declined' ||
    (a.value === 'other ' && b.value !== 'declined') ||
    (a.value === 'multiracial' && b.value !== 'declined' && b.value !== 'other') ||
    (a.value > b.value &&
      b.value !== 'declined' &&
      b.value !== 'other' &&
      b.value !== 'multiracial')
      ? 1
      : 1;

  handleBioBlur({ target: { classList } }) {
    const { length: bioLength } = this.state.profile.bio;
    if (bioLength < 1) {
      classList.add('invalid-field');
    } else {
      classList.remove('invalid-field');
    }
  }

  handleBioChange({ target: { value } }) {
    const {
      minBioChar,
      maxBioChar,
      bioTooLong,
      bioTooShort,
      bioEmpty,
      profile: updateProfile,
    } = this.state;
    // keep state of complex object
    updateProfile.bio = value;
    // Update bio state when user types
    this.setState({ profile: updateProfile });
    // If bio is too short set state for validation
    const tooShort = value.length < minBioChar;
    this.setState({ bioTooShort: tooShort, bioEmpty: false });
    // If bio is too long set state for validation
    const tooLong = value.length > maxBioChar;
    this.setState({ bioTooLong: tooLong, bioEmpty: false });
    // If bio is empty set state for validation
    if (value.length === 0) this.setState({ bioEmpty: true, bioTooShort: false });
    // logic to disable save button
    if (bioTooLong || bioTooShort || bioEmpty) {
      this.setState({ disableSave: true });
    } else {
      this.setState({ disableSave: false });
    }
    this.getCharCount();
  }

  handleEthnicityChange = ({ target: { value } }) => {
    const { profile: updateProfile } = this.state;
    updateProfile.ethnicity = value || null;
    this.setState({ profile: updateProfile, isEthnicitySelected: !!value });
  };

  handleGenderChange = ({ target: { value } }) => {
    const { profile: updateProfile } = this.state;
    updateProfile.gender = value || null;
    this.setState({ profile: updateProfile });
  };

  handleDirectoryParticipationChange = ({ target: { value } }) => {
    const { profile: updateProfile } = this.state;
    updateProfile.directoryParticipation = value == 'true';
    this.setState({ profile: updateProfile });
  };

  isRegistrationFlagOn() {
    getAdminConfigValue('registration_V2')
      .then((flagValue) => {
        if (flagValue) {
          this.setState({ websiteDirectLinkURL: `${apiHelper().clientWebEndpoint}/signup/` });
        }
      })
      .catch();
  }

  saveForm() {
    const {
      profile,
      profile: { bio, ethnicity, gender, directoryParticipation },
    } = this.state;
    const userID = getUserData().id;
    const psychologyTodayId = this.verifyPsychologyTodayId();
    this.setState({ disableSave: true, profile: { ...profile, psychologyTodayId } });

    return apiWrapper
      .put(`${apiHelper().apiEndpoint}/api/v1/therapist/${userID}/profile-info`, {
        bio,
        gender,
        ethnicity,
        psychologyTodayId,
        directoryParticipation,
      })
      .then((response) => {
        if (response.status == 200) {
          toast(<div className="toaster toaster-success">Changes are saved successfully!</div>, {
            autoClose: 3000,
          });
          this.toggleFormEdit();
          this.setState({ disableSave: false });
        }
      })
      .catch((err) => {
        toast(
          <div
            className="toaster toaster-error"
            style={{ background: '#e8006b', color: '#FFF', padding: '12px', fontSize: '16px' }}
          >
            Changes did not save successfully!
          </div>,
          { autoClose: 3000 }
        );
        this.setState({ disableSave: false });
      });
  }

  toggleFormEdit() {
    const {
      isEdit: editBool,
      profile: { bio, psychologyTodayId },
    } = this.state;
    this.setState({ isEdit: !editBool });
    if (!editBool) {
      this.getCharCount();
      this.setState({
        profileSave: bio,
        articleLinkSave: psychologyTodayId,
      });
    }
    const actionItems = document.querySelectorAll([
      '#profile .btn-ts-default',
      '#profile .ts-edit-button',
      '.bio-textarea',
      '.ts-profile-read-only',
      '.ts-profile-psychology-today',
      '.char-count',
      '.ts-profile-psychology-today-url-muted',
      '.ts-profile-psychology-today-url',
    ]);
    [].forEach.call(actionItems, (item) => {
      item.classList.toggle('hidden');
    });
  }

  verifyPsychologyTodayId() {
    const { psychologyTodayId } = this.state.profile;
    // Valid ID would have at least one non-zero digit
    if (Number.parseInt(psychologyTodayId) > 0) {
      return psychologyTodayId;
    }
    // Prevent saving invalid ID on DB by sending an empty string
    return '';
  }

  render() {
    const title = 'Profile';
    const tooltip = (
      <Tooltip id="profileTooltip" className="tooltip ts-tooltip ts-profile-text-white">
        Profile information is used to introduce you to clients. A strong Bio will increase your
        chances of clients choosing your as their therapist. The Psychology Today link helps clients
        find more relevant information about you easily.
      </Tooltip>
    );
    const directoryParticipationTooltip = (
      <Tooltip
        id="directoryParticipationTooltip"
        className="tooltip ts-tooltip ts-profile-text-white"
      >
        The Talkspace directory is visible in internet searches. By selecting no you will not be
        visible in the directory.
      </Tooltip>
    );
    const disableSave = this.state.disableSave || !this.state.isEthnicitySelected;
    return (
      <div>
        <Col
          xs={12}
          id="profile"
          className={`ts-panel-title ${this.state.isLoaded ? 'show-panel' : 'hidden-panel'}`}
        >
          <Col xs={4} className="ts-font-black">
            {title}
            <OverlayTrigger
              placement="right"
              trigger={['click', 'focus', 'hover']}
              overlay={tooltip}
            >
              <i className="fa fa-fw fa-question-circle fa-lg" />
            </OverlayTrigger>
          </Col>
          <Col xs={6} />
          <Button className="ts-edit-button pull-right" onClick={this.toggleFormEdit}>
            Edit
          </Button>
          <Button
            className="btn-ts-default btn-ts-green ts-profile-text-white pull-right hidden save-btn"
            onClick={this.saveForm}
            disabled={disableSave}
          >
            Save Changes
          </Button>
          <Button
            className="btn-ts-default ts-profile-btn-text-green cancel-btn pull-right hidden"
            onClick={this.cancelEdit}
          >
            Cancel
          </Button>
        </Col>
        <Col xs={12}>
          <Panel
            className={`ts-my-account-panel ${this.state.isLoaded ? 'show-panel' : 'hidden-panel'}`}
          >
            <Row style={{ marginBottom: '20px' }}>
              <Col md={2} sm={3} xs={5}>
                <img
                  alt="profile"
                  width="105"
                  height="105"
                  src={this.getProfileImg()}
                  onError={(e) => {
                    // eslint-disable-next-line no-param-reassign
                    e.target.onerror = null;
                    // eslint-disable-next-line no-param-reassign
                    e.target.src = profileDefault;
                  }}
                />
              </Col>
              <Col md={3} sm={3} xs={7}>
                <p className="ts-profile-sm-muted-label">First Name</p>{' '}
                <p className="ts-profile-name ts-profile-first-name">
                  {this.state.profile.firstName}
                </p>
              </Col>
              <Col md={3} sm={3} xs={7}>
                <p className="ts-profile-sm-muted-label">Last Name</p>{' '}
                <p className="ts-profile-name">{this.state.profile.lastName}</p>
              </Col>
              <Col md={3} sm={3} xs={7} smOffset={0} xsOffset={5}>
                <p className="ts-profile-sm-muted-label">Therapist ID</p>{' '}
                <p className="ts-profile-name">{this.state.userID}</p>
              </Col>
              <Col md={10} sm={12}>
                <p className="ts-profile-sm-muted-label profile-bio">Bio</p>
                <p
                  className="ts-profile-bio ts-profile-text-padding"
                  style={{ marginTop: '12px' }}
                />
                <Row>
                  <Col md={11} sm={12}>
                    <FormGroup controlId="bio-form" className="bio-textarea hidden" ref="bio">
                      <FormControl
                        componentClass="textarea"
                        name="bio"
                        className="ts-profile-textarea ts-profile-bio"
                        value={this.state.profile.bio}
                        onChange={this.handleBioChange}
                        onKeyUp={this.handleBioChange}
                        onBlur={this.handleBioBlur}
                        placeholder="Please type your biography here."
                      />
                    </FormGroup>
                    <p className="ts-profile-bio ts-profile-read-only">{this.state.profile.bio}</p>

                    <p className="ts-profile-sm-muted-label pull-right ts-text-margin char-count hidden">
                      {this.state.charsLeft} characters left.
                    </p>
                    <p className="ts-text-invalid ts-text-margin" hidden={!this.state.bioEmpty}>
                      Bio is required.
                    </p>
                    <p className="ts-text-invalid ts-text-margin" hidden={!this.state.bioTooShort}>
                      Bio is too short.
                    </p>
                    <p className="ts-text-invalid ts-text-margin" hidden={!this.state.bioTooLong}>
                      Bio is too long.
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={2} style={{ paddingRight: 0 }}>
                <p className="ts-profile-label">
                  Directory Participation
                  <OverlayTrigger
                    placement="top"
                    trigger={['click', 'focus', 'hover']}
                    overlay={directoryParticipationTooltip}
                  >
                    <i className="fa fa-fw fa-question-circle fa-lg" />
                  </OverlayTrigger>
                </p>
              </Col>
              <Col sm={4}>
                <FormGroup controlId="directory-participation-form">
                  <FormControl
                    componentClass="select"
                    placeholder="select"
                    onChange={this.handleDirectoryParticipationChange}
                    value={String(this.state.profile.directoryParticipation)}
                    disabled={!this.state.isEdit}
                  >
                    <>
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </>
                  </FormControl>
                </FormGroup>
              </Col>
            </Row>
            <Row style={{ marginBottom: '10px' }}>
              <Col md={2}>
                <p className="ts-profile-label">Website</p>
              </Col>
              <Col md={10}>
                <p className="ts-profile-text-green ts-profile-text-padding">
                  {this.state.websiteDirectLinkURL}
                  {this.state.profile.website}
                </p>
              </Col>
            </Row>
            <Row>
              <Col md={2} style={{ paddingRight: '0px' }}>
                <p className="ts-profile-label">Psychology Today</p>
              </Col>
              <Col md={10}>
                <span>
                  <p className="ts-profile-text-green ts-profile-text-padding pull-left ts-profile-psychology-today-url">
                    https://therapists.psychologytoday.com/
                    {this.state.profile.psychologyTodayId || ''}
                  </p>
                  <p className="ts-profile-text-muted ts-profile-text-padding pull-left ts-profile-psychology-today-url-muted hidden">
                    https://therapists.psychologytoday.com/
                  </p>
                  <FormControl
                    name="psychologyTodayId"
                    type="text"
                    value={this.state.profile.psychologyTodayId || ''}
                    placeholder="12345"
                    onChange={this.articleLinkChange}
                    className="form-control input-sm ts-profile-text pull-left hidden ts-profile-psychology-today"
                  />
                </span>
              </Col>
              <Col md={10}>
                <p className="ts-text-invalid ts-text-margin" hidden={!this.state.articleLinkError}>
                  Psychology today Id must be numeric.
                </p>
              </Col>
            </Row>
            <Row>
              <Col md={2} style={{ paddingRight: 0 }}>
                <p className="ts-profile-label">Race / Ethnicity</p>
              </Col>
              <Col sm={4}>
                <FormGroup controlId="race-form">
                  <FormControl
                    componentClass="select"
                    placeholder="select"
                    onChange={this.handleEthnicityChange}
                    value={this.state.profile.ethnicity}
                    disabled={!this.state.isEdit}
                  >
                    <>
                      <option value="">Select race/ethnicity</option>
                      {getOptionsByField('ethnicity')
                        .sort(this.compareEthnicites)
                        .map(({ value, label }) => (
                          <option key={value} value={value}>
                            {label}
                          </option>
                        ))}
                    </>
                  </FormControl>
                </FormGroup>
                <p
                  className="ts-text-invalid ts-text-margin"
                  hidden={this.state.isEthnicitySelected}
                >
                  {' '}
                  You must select an ethnicity{' '}
                </p>
              </Col>
            </Row>
            <Row>
              <Col md={2} style={{ paddingRight: 0 }}>
                <p className="ts-profile-label">Gender / Orientation</p>
              </Col>
              <Col sm={4}>
                <FormGroup controlId="orientation-form">
                  <FormControl
                    componentClass="select"
                    placeholder="select"
                    onChange={this.handleGenderChange}
                    value={this.state.profile.gender}
                    disabled={!this.state.isEdit}
                  >
                    <>
                      <option value="">Select gender/orientation</option>
                      {getOptionsByField('gender')
                        .sort((a, b) =>
                          a.value === 'gender-other' ||
                          (a.value > b.value && b.value !== 'gender-other')
                            ? 1
                            : -1
                        )
                        .map(({ value, label }) => (
                          <option key={value} value={value}>
                            {label}
                          </option>
                        ))}
                    </>
                  </FormControl>
                </FormGroup>
              </Col>
            </Row>
          </Panel>
        </Col>
        <ToastContainer
          closeButton={false}
          position={toast.POSITION.BOTTOM_RIGHT}
          hideProgressBar
        />
      </div>
    );
  }
}

export default TherapistProfile;
