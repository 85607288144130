import { useState, ReactNode, memo } from 'react';
import {
  AnimatedSwipeDownModal,
  Divider,
  getSpacing,
  Spinner,
  styled,
  TextDS,
  TooltipV2,
  TouchableView,
  useEmotionTheme,
  useWindowWidth,
  View,
  Link,
} from '@talkspace/react-toolkit';
import {
  Video,
  Envelope,
  PalmTree,
  CircleQuestion,
} from '@talkspace/react-toolkit/src/designSystems/icons';
import useQueryClinicalHours from '../queries/useQueryClinicalHours';
import { getUserData } from '../../../../utils/token';
import { formatWeekPeriod } from '../utils/date';
import { navigateToBillableHoursZendesk } from '../utils/zendesk';

const TooltipContentView = styled(View)({
  padding: getSpacing('space200'),
  gap: getSpacing('space100'),
});

interface TooltipContentProps {
  header: string;
  body: ReactNode;
}

const TooltipContent = ({ header, body }: TooltipContentProps) => (
  <TooltipContentView>
    <TextDS variant="headingMd">{header}</TextDS>
    <TextDS>{body}</TextDS>
  </TooltipContentView>
);

const MessagingTooltipContent = () => (
  <TooltipContent
    header="How are hours counted for messaging?"
    body="Hours are calculated at 40 words per minute for texting, 150 words per PDF file, and message
      duration for audio and video messages."
  />
);

const PTOTooltipContent = () => (
  <TooltipContent
    header="How are hours counted for paid time off?"
    body={`PTO hours are counted based on the time you add in the "Time Off" section under "My Account." Note that this information updates once per day when the page refreshes.`}
  />
);

const WordCapTooltipContent = () => {
  const { colorRoles } = useEmotionTheme();
  return (
    <>
      <TooltipContentView>
        <TextDS variant="headingMd">Why is my word count capped?</TextDS>
        <TextDS>
          You’ve exceeded twice the word count of your clients. You can only receive credit for up
          to twice the words that your clients send.
          <Link
            isNested
            stopPropagation
            onClick={() => navigateToBillableHoursZendesk()}
            text="Learn more"
            target="_blank"
            primaryColor={colorRoles.typography.textInteractiveDefault}
            style={{
              display: 'block',
              textDecoration: 'none',
              fontWeight: 500,
              textDecorationLine: 'underline',
              margin: 0,
            }}
          />
        </TextDS>
      </TooltipContentView>
    </>
  );
};

const tooltipContainerStyles = {
  left: 20,
  width: 354,
  top: 0,
  padding: 0,
};

const tooltipStyles = {
  marginLeft: -1 * getSpacing('space050', false),
};

const MainView = styled(View)({
  gap: getSpacing('space300', false),
  maxWidth: 460,
});

const TitleView = styled(View)({
  flexDirection: 'row',
  gap: getSpacing('space100', false),
  alignItems: 'baseline',
});

const SectionView = styled(View)({
  gap: getSpacing('space100', false),
});

const SectionTitleView = styled(View)({
  flexDirection: 'row',
  justifyContent: 'space-between',
});

const SectionTitleWithIcon = styled(SectionTitleView)({
  flexDirection: 'row',
  gap: getSpacing('space100'),
  alignItems: 'center',
});

const DataRowView = styled(SectionTitleView)({
  marginLeft: 20 + getSpacing('space100'),
});

interface DataEntryProps {
  label: ReactNode;
  value: string;
}

const DataRow = ({ label, value }: DataEntryProps) => {
  const { isMobile } = useWindowWidth();
  return (
    <DataRowView>
      <TextDS style={{ maxWidth: isMobile ? 208 : undefined }}>{label}</TextDS>
      <TextDS>{value}</TextDS>
    </DataRowView>
  );
};

interface ResponsiveTooltipProps {
  isOpen: boolean;
  onDrawerOpen: () => void;
  onDrawerClose: () => void;
  legendContent: ReactNode;
}

const ResponsiveTooltip = ({
  isOpen,
  onDrawerOpen,
  onDrawerClose,
  legendContent,
}: ResponsiveTooltipProps) => {
  const { isMobile } = useWindowWidth();

  if (isMobile) {
    return (
      <>
        <TouchableView style={tooltipStyles} onPress={onDrawerOpen}>
          <CircleQuestion colorType={isOpen ? 'brand' : 'subtlest'} />
        </TouchableView>

        <AnimatedSwipeDownModal
          isOpen={isOpen}
          onClose={onDrawerClose}
          containerStyles={{ padding: getSpacing('space200') }}
          shouldDisplayCloseButton={false}
        >
          {legendContent}
        </AnimatedSwipeDownModal>
      </>
    );
  }

  return (
    <TooltipV2
      shouldDisplayCloseButton={false}
      containerStyle={tooltipContainerStyles}
      styles={tooltipStyles}
      toolTipText={legendContent}
      isTooltipOpen={isOpen}
    />
  );
};

type Legend = 'messaging' | 'pto' | 'wordCap';

const WeekTable = () => {
  const therapistID = getUserData().id;

  const { data, isLoading } = useQueryClinicalHours(therapistID);

  const [activeLegend, setActiveLegend] = useState<Legend | undefined>();

  if (isLoading || !data) {
    return <Spinner />;
  }

  const {
    weeklyStats: [, thisWeek],
  } = data;

  const isWordsCapReached =
    thisWeek.providerTotalMessagingWordsUncapped !== thisWeek.providerTotalMessagingWordsCapped;

  const Title = memo(() => (
    <TitleView>
      <TextDS variant="headingLg">Hours this week</TextDS>
      <TextDS>{formatWeekPeriod(thisWeek.weekStart, thisWeek.weekEnd)}</TextDS>
    </TitleView>
  ));

  const LiveSessionsSection = memo(() => (
    <SectionView>
      <SectionTitleView>
        <SectionTitleWithIcon>
          <Video colorType="subtle" />
          <TextDS variant="headingMd">Live Sessions</TextDS>
        </SectionTitleWithIcon>

        <TextDS variant="headingMd">{thisWeek.totalLiveCallHours} hrs</TextDS>
      </SectionTitleView>

      <DataRow label="Time in session with client" value={`${thisWeek.liveCallHours} hrs`} />

      <DataRow
        label="Client no-shows and late cancellations"
        value={`${thisWeek.noShowsAndLateCancellationsHours} hrs`}
      />
    </SectionView>
  ));

  const MessagingSection = memo(() => (
    <SectionView>
      <SectionTitleView>
        <SectionTitleWithIcon>
          <Envelope colorType="subtle" />

          <TextDS variant="headingMd">Messaging</TextDS>

          <ResponsiveTooltip
            isOpen={activeLegend === 'messaging'}
            onDrawerOpen={() => setActiveLegend('messaging')}
            onDrawerClose={() => setActiveLegend(undefined)}
            legendContent={<MessagingTooltipContent />}
          />
        </SectionTitleWithIcon>

        <TextDS variant="headingMd">{thisWeek.totalMessagingHours} hrs</TextDS>
      </SectionTitleView>

      <DataRowView>
        <TextDS>
          Writing text{' '}
          {!isWordsCapReached ? (
            <TextDS
              colorRole="textSubtlest"
              inline
              style={{ display: 'flex', alignItems: 'center' }}
            >
              ({thisWeek.providerTotalMessagingWordsUncapped} words, capped at{' '}
              {thisWeek.providerTotalMessagingWordsCapped})
              <View
                style={{
                  display: 'inline-flex',
                  marginLeft: getSpacing('space100'),
                }}
              >
                <ResponsiveTooltip
                  isOpen={activeLegend === 'wordCap'}
                  onDrawerOpen={() => setActiveLegend('wordCap')}
                  onDrawerClose={() => setActiveLegend(undefined)}
                  legendContent={<WordCapTooltipContent />}
                />
              </View>
            </TextDS>
          ) : (
            <TextDS colorRole="textSubtlest" inline>
              ({thisWeek.providerTotalMessagingWordsUncapped} words)
            </TextDS>
          )}
        </TextDS>
        <TextDS>{thisWeek.providerTotalMessagingHoursCapped} hrs</TextDS>
      </DataRowView>

      <DataRow
        label={
          <>
            Reading text{' '}
            <TextDS colorRole="textSubtlest" inline>
              ({thisWeek.clientTotalMessagingWords} words)
            </TextDS>
          </>
        }
        value={`${thisWeek.clientTotalMessagingHours} hrs`}
      />

      <DataRow label="Recording audio & video" value={`${thisWeek.providerAVHours} hrs`} />

      <DataRow label="Listening to audio & video" value={`${thisWeek.clientAVHours} hrs`} />
    </SectionView>
  ));

  const PTOSection = memo(() => (
    <SectionView>
      <SectionTitleView>
        <SectionTitleWithIcon>
          <PalmTree colorType="subtle" />

          <TextDS variant="headingMd">Paid time off</TextDS>

          <ResponsiveTooltip
            isOpen={activeLegend === 'pto'}
            onDrawerOpen={() => setActiveLegend('pto')}
            onDrawerClose={() => setActiveLegend(undefined)}
            legendContent={<PTOTooltipContent />}
          />
        </SectionTitleWithIcon>

        <TextDS variant="headingMd">{thisWeek.paidTimeOffHours} hrs</TextDS>
      </SectionTitleView>
    </SectionView>
  ));

  const TotalSection = memo(() => (
    <View>
      <SectionTitleView>
        <TextDS variant="headingMd">Total hours</TextDS>
        <TextDS variant="headingMd">{thisWeek.totalHours} hrs</TextDS>
      </SectionTitleView>
    </View>
  ));

  return (
    <MainView>
      <Title />

      <LiveSessionsSection />

      <MessagingSection />

      <PTOSection />

      <Divider />

      <TotalSection />
    </MainView>
  );
};

export default WeekTable;
