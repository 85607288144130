import { getSpacing, styled, TextDS, View, BannerCard, Spinner } from '@talkspace/react-toolkit';
import { HourglassHalf, StarShooting } from '@talkspace/react-toolkit/src/designSystems/icons';
import { formatCurrency } from 'ts-frontend/helpers/billingUtils';

import CalloutNumber from './CalloutNumber';
import useQueryClinicalHours from '../queries/useQueryClinicalHours';
import { getUserData } from '../../../../utils/token';
import { formatQuarterEndDate, isFirstWeekOfQuarter } from '../utils/date';
import BonusInfoView from './BonusInfoView';

const MainView = styled(View)(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      flexDirection: isMobile ? 'column' : 'row',
      alignItems: isMobile ? 'center' : 'start',
      gap: getSpacing(isMobile ? 'space200' : 'space300'),
    };
  }
);

const QuarterView = () => {
  const therapistID = getUserData().id;

  const { data, isLoading } = useQueryClinicalHours(therapistID);

  if (isLoading || !data) {
    return <Spinner />;
  }

  if (!data.quarterOverview || !data.bonusInfo) {
    return null;
  }

  const {
    bonusInfo,
    quarterOverview: { average, weeksLeft, lastDay },
    weeklyHoursGoal,
    bonusThresholds,
  } = data;

  if (isFirstWeekOfQuarter(new Date())) {
    const biggestBonus = formatCurrency(Math.max(...bonusThresholds.map((it) => it.amount)), 'USD');
    const smallestBonus = formatCurrency(
      Math.min(...bonusThresholds.map((it) => it.amount)),
      'USD'
    );
    return (
      <View align="center">
        <BannerCard
          style={{ maxWidth: '100%' }}
          variant="info"
          title={`Earn a bonus of up to ${biggestBonus}`}
          subtitle={`Once this week is complete, your average weekly hours and quarterly bonus progress will display here. Earn a ${smallestBonus} bonus if you average ${weeklyHoursGoal} hours per week this quarter—and the bonus increases from there!`}
          icon={<StarShooting size="major" colorType="info" />}
        />
      </View>
    );
  }

  return (
    <MainView>
      <CalloutNumber
        title="Average this quarter"
        metric={`${average} hrs/wk`}
        type={Number(average) >= weeklyHoursGoal ? 'success' : 'default'}
        showIcon={false}
      />

      <View style={{ gap: getSpacing('space200'), padding: getSpacing('space200') }}>
        <View row style={{ gap: getSpacing('space200') }}>
          <HourglassHalf size="major" colorType="success" />

          <TextDS>
            {weeksLeft} weeks left in the quarter (ends on {formatQuarterEndDate(lastDay)})
          </TextDS>
        </View>

        <BonusInfoView bonusInfo={bonusInfo} weeklyHoursGoal={weeklyHoursGoal} />
      </View>
    </MainView>
  );
};

export default QuarterView;
